import { UserProfile } from './authentication';
import { FilterComparators, O8RootQueryFilter } from './db';
import { SUPPORTED_STATE } from './global';
import { LEAD_STATUS } from './leads';

export enum CallTranscriptStatus {
  COMPLETED = 'completed',
  QUEUED = 'queued',
  PROCESSING = 'processing',
  ERROR = 'error',
}

export interface CallTranscriptWord {
  text: string;
  start: number;
  end: number;
  confidence: number;
  speaker: SpeakerId;
}

export type CallTranscriptUtterance = CallTranscriptWord & { words: CallTranscriptWord[] };

export enum CallTranscriptSentiment {
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export interface SentimentAnalysis {
  text: string;
  start: number;
  end: number;
  sentiment: CallTranscriptSentiment;
  confidence: number;
  speaker: string;
}

export interface CallTranscript {
  id: string;
  status: CallTranscriptStatus;
  text?: string;
  confidence?: number;
  word_boost?: string[];
  words: CallTranscriptWord[];
  utterances: CallTranscriptUtterance[];
  sentiment_analysis_results: SentimentAnalysis[];
}

export interface CallTranscriptRecording {
  url: string | null;
}

export interface FullTranscriptInfo {
  audio_file: CallTranscriptRecording;
  transcript: CallTranscript;
}

export type SpeakerId =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z';

export interface SpeakerLabel {
  id: string;
  taskId: string;
  speakerId: SpeakerId;
  type: SpeakerLabelType;
  value: string;
  version: string;
  isDeleted: boolean;
}

export enum SpeakerLabelType {
  LEAD = 'Lead',
  ADVOCATE = 'Advocate',
  OTHER = 'Other',
}

export interface TagUtterance {
  text: string;
  icon: string;
  class: string;
}

export interface CallTranscriptTagDefinition {
  id: string;
  type: string; // Tag type used by the AI model, acts as an ID on the data-science side
  label: string; //  Label displayed in the UI
  products: string[]; //  List of products for which this tag applies. Value is either ['All'] or a list of products it applies to.
  carriers: string[]; //  List of carries for which this tag applies. Value is either ['All'] or a list of carriers it applies to.
  pods: string[]; //  List of pods for which this tag applies. Value is either ['All'] or a list of pods it applies to.
  callTypes: string[]; //  List of call types for which this tag applies. Value is either ['All'] or a list of call types it applies to.
  isDisplayed: boolean; //  if false, the tag won't show in the UI
  isActive: boolean; //  true if tags should still be assignable and used
  category: string; // Category of the tag
  version: string;
  timestamp: Date;
  linkedRecommendedResponses?: RecommendedResponse[];
}

export enum TagInTranscriptFeedbackScore {
  NEGATIVE = -1,
  POSITIVE = 1,
}

export interface TagInTranscriptFeedback {
  id: string;
  userEmail: string; // Email of the user who added the feedback
  tagInTranscriptId: string; // Matches id from TagInCallTranscript
  score: TagInTranscriptFeedbackScore; //  Either +1 (thumbs up) or -1 (thumbs down)
  timestamp: Date;
  version: string;
}

export const TAG_IN_TRANSCRIPT_FEEDBACK_VERSION = '1.0.0';

export interface TagInTranscript {
  id: string;
  user: string; // Either user email or simply 'system'
  tagId: string; // Matches uuid in CallTranscriptTagDefinition
  transcriptId: string; // atches id from current transcript
  start: number; //  Place in the transcript
  timestamp: Date;
  isDeleted: boolean;
  version: string;
  definition: CallTranscriptTagDefinition; // Matching definition based on the tagId field
  feedbacks: TagInTranscriptFeedback[]; // Associated feedbacks based on the id field
  userInfo: Pick<UserProfile, 'firstName' | 'lastName'> | null;
  uiGenerated?: boolean; // Remove property in OWEB-802, only defined and true when tag was generated by legacy tag in transcript
}

export const TAG_IN_TRANSCRIPT_VERSION = '1.0.0';

/* If you update here, don't forget to update the enum in the GQL schema in PEW apps\pipeline-event-writer\src\schema.graphql*/
export enum QUALIFYING_QUESTION_CATEGORY {
  COMPLIANCE = 'Compliance',
  ACCURACY = 'Accuracy',
  LIMITING_OBJECTIONS = 'Limiting Objections',
  INQUIRE = 'Inquire',
  BRAND_ALIGNMENT = 'Brand Alignment',
  RECAP = 'Recap',
}

export const OVERALL_GRADE_CATEGORY = 'Overall Score';

export const QUALIFYING_QUESTION_CATEGORY_WITH_SORTING_ORDER = [
  { category: QUALIFYING_QUESTION_CATEGORY.COMPLIANCE, order: 1 },
  { category: QUALIFYING_QUESTION_CATEGORY.ACCURACY, order: 2 },
  { category: QUALIFYING_QUESTION_CATEGORY.LIMITING_OBJECTIONS, order: 3 },
  { category: QUALIFYING_QUESTION_CATEGORY.BRAND_ALIGNMENT, order: 5 },
  { category: QUALIFYING_QUESTION_CATEGORY.RECAP, order: 6 },
  { category: QUALIFYING_QUESTION_CATEGORY.INQUIRE, order: 4 },
];
/* If you update here, don't forget to update the enum in the GQL schema in PEW apps\pipeline-event-writer\src\schema.graphql*/
export enum QUALIFYING_QUESTION_TOPIC {
  INTRODUCTION = 'Introduction',
  NEEDS_ASSESSMENT = 'Needs Assessment',
  TRANSFER_HANDOVER = 'Transfer & Hand over',
  REPLACEMENT = 'Replacement',
  PRODUCT_FEATURES_AND_BENEFITS = 'Product Features And Benefits',
  SELECT_COVERAGE = 'Select Coverage',
  TWO_YEAR_LIMITED_BENEFIT_PERIOD = '2 Year Limited Benefit Period',
  POLICY_PERMISSION = 'Policy Permission',
  ACCIDENTAL_DEATH_RIDER = 'Accidental Death Rider',
  BENEFICIARIES = 'Beneficiaries',
  BILLING_AND_VOICE_AUTHORIZATION = 'Billing & Voice Authorization',
  EFFECTIVE_DATE = 'Effective Date',
  PERSEVERANCE = 'Perseverance',
  RECAP = 'Recap',
  PERFORMANCE = 'Performance',
}

export const QUALIFYING_QUESTION_TOPIC_WITH_SORTING_ORDER = [
  { topic: QUALIFYING_QUESTION_TOPIC.INTRODUCTION, order: 1 },
  { topic: QUALIFYING_QUESTION_TOPIC.NEEDS_ASSESSMENT, order: 2 },
  { topic: QUALIFYING_QUESTION_TOPIC.TRANSFER_HANDOVER, order: 2 },
  { topic: QUALIFYING_QUESTION_TOPIC.REPLACEMENT, order: 3 },
  { topic: QUALIFYING_QUESTION_TOPIC.PRODUCT_FEATURES_AND_BENEFITS, order: 4 },
  { topic: QUALIFYING_QUESTION_TOPIC.SELECT_COVERAGE, order: 5 },
  { topic: QUALIFYING_QUESTION_TOPIC.TWO_YEAR_LIMITED_BENEFIT_PERIOD, order: 6 },
  { topic: QUALIFYING_QUESTION_TOPIC.POLICY_PERMISSION, order: 7 },
  { topic: QUALIFYING_QUESTION_TOPIC.ACCIDENTAL_DEATH_RIDER, order: 8 },
  { topic: QUALIFYING_QUESTION_TOPIC.BENEFICIARIES, order: 9 },
  { topic: QUALIFYING_QUESTION_TOPIC.BILLING_AND_VOICE_AUTHORIZATION, order: 10 },
  { topic: QUALIFYING_QUESTION_TOPIC.EFFECTIVE_DATE, order: 11 },
  { topic: QUALIFYING_QUESTION_TOPIC.PERSEVERANCE, order: 12 },
  { topic: QUALIFYING_QUESTION_TOPIC.RECAP, order: 13 },
  { topic: QUALIFYING_QUESTION_TOPIC.PERFORMANCE, order: 14 },
];

export interface QualifyingQuestion {
  id: string;
  label: string;
  category: QUALIFYING_QUESTION_CATEGORY;
  topic: QUALIFYING_QUESTION_TOPIC;
  isDeleted: boolean;
  products: string[]; //  List of products for which this tag applies. Value is either ['All'] or a list of products it applies to.
  carriers: string[]; //  List of carries for which this tag applies. Value is either ['All'] or a list of carriers it applies to.
  pods: string[]; //  List of pods for which this tag applies. Value is either ['All'] or a list of pods it applies to.
  callTypes: string[]; //  List of call types for which this tag applies. Value is either ['All'] or a list of call types it applies to.
  version: string;
  order: number;
  isExcludedInGrading: boolean; // true if the question should not be counted in grading/scoring
}

export enum QUALIFYING_ANSWER_VALUE {
  N_A = 'N_A',
  HALF = 'Half',
  NO = 'No',
  YES = 'Yes',
}

export interface QualifyingAnswer {
  id: string;
  value: QUALIFYING_ANSWER_VALUE;
  questionId: string /* QualifyingQuestion  Id */;
  transcriptId: string /* Call transcript SF Id */;
  answeredBy: string /* User email */;
  isDeleted: boolean;
  version: string;
  question?: QualifyingQuestion;
}

export const QUALIFYING_ANSWER_VERSION = '1.0.0';

export const SYSTEM_USER = 'system';

export interface CallReviewNote {
  content: string;
  lastEditedBy: string;
  ownerName: string;
  lastEditedOn: Date;
  start: number;
}

export interface CallTranscriptReview {
  id: string /* Random uuid v4 */;
  taskId: string /* Matches the task id in SF and the id in the URL to load the call transcript*/;
  analysisId: string /*Assembly AI ID */;
  graded: boolean /* Whether the QA team graded the call or not*/;
  lastGradedAt: Date | null;
  lastGradedBy: String | null;
  version: string;
  answers?: QualifyingAnswer[];
  tags?: TagInTranscript[];
  notes: CallReviewNote[];
  taskInfo?: CallTranscriptTask;
}

export const CALL_TRANSCRIPT_REVIEW_VERSION = '1.0.0';

export type CallTranscriptReviewGrading = {
  [key in QUALIFYING_QUESTION_CATEGORY]: number | null;
};

export interface CallTranscriptTask {
  id: string;
  ownerId: string;
  leadStatus: LEAD_STATUS;
  pod: string;
  leadId: string;
  leadName: string;
  leadCreatedTime: Date;
  leadSource: string;
  state: SUPPORTED_STATE;
  carrier: string;
  product: string;
  positionDetail: string;
  leadTakenTimestamp: Date;
  startCallTime: Date | null;
  endCallTime: Date | null;
  hangUpReason: string | null;
  userInfo?: UserProfile;
  callDisposition: string;
  callDuration: number | null;
  graded: boolean;
  callType: CALL_TYPE;
  speakerLabels?: SpeakerLabel[];
}

export type GradedCallTranscriptReview = Omit<CallTranscriptReview, 'graded'> & {
  tagCount?: {
    [key: string]: number;
  };
  taskInfo: CallTranscriptTask;
};
export type GradedCallTranscriptReviewWithScore = GradedCallTranscriptReview & {
  score: CallTranscriptReviewGrading;
  overallScore: number | null;
};

export enum ArcTagFilterComparator {
  EXIST = 'Exist',
  NOT_EXIST = 'Not Exist',
}
export interface ArcTagFilter {
  comparator: ArcTagFilterComparator;
  tagDefinitionId: string /* CallTranscriptTagDefinition id */;
}

export interface ArcQualifyingQuestionAnswerFilter {
  questionId: string /* QualifyingQuestion id */;
  value: QUALIFYING_ANSWER_VALUE;
}

export type ArcTagCountFilterComparator = FilterComparators.GREATER | FilterComparators.LOWER | FilterComparators.EQUAL;

export type ArcTagCountFilter = {
  specificQueryBuilderId: string;
  comparator: ArcTagCountFilterComparator;
  value: number;
};

export type ArcSpecificFilter = ArcTagCountFilter;

export enum ARC_SPECIFIC_QUERY_BUILDER_TYPE {
  TAG_COUNT = 'TagCount',
  TAG_COUNT_DISTINCT = 'TagCountDistinct',
}

export const TAG_COUNT_QUERY_BUILDERS = [
  ARC_SPECIFIC_QUERY_BUILDER_TYPE.TAG_COUNT,
  ARC_SPECIFIC_QUERY_BUILDER_TYPE.TAG_COUNT_DISTINCT,
];

export const AVAILABLE_ARC_SPECIFIC_QUERY_BUILDER_TYPE = Object.values(ARC_SPECIFIC_QUERY_BUILDER_TYPE);

export interface ArcSpecificQueryBuilder<T extends object = object> {
  id: string;
  outputField: string;
  type: ARC_SPECIFIC_QUERY_BUILDER_TYPE;
  matchConditions: O8RootQueryFilter<T>;
  label: string;
  version: string;
  isDeleted: boolean;
  isDisplayed: boolean;
}

export const ARC_SPECIFIC_QUERY_BUILDER_VERSION = '1.0.0';

export interface ArcFilteredView {
  id: string;
  user: string /* 'system' or user email */;
  label: string /* name of the filtered view */;
  filtersSnapshot: FiltersSnapshot;
  isDeleted: boolean;
  version: string;
}

export interface FiltersSnapshot {
  tagFilters: ArcTagFilter[];
  questionFilters: ArcQualifyingQuestionAnswerFilter[];
  specificFilters: ArcSpecificFilter[];
  gridFilters: ArcGridFilter[];
}

export interface ArcGridFilter {
  columnName: string;
  columnFilterConfig: GridColumnFilterConfig;
}

export type GridColumnFilterConfig =
  | {
      filterType: 'number';
      filterOption: string;
      filterValue: number;
      joinOperator: null;
      joinConditions: null;
    }
  | {
      filterType: 'number';
      filterOption: null;
      filterValue: null;
      joinOperator: string;
      joinConditions: GridColumnJoinCondition[];
    }
  | {
      filterType: 'text';
      filterOption: string;
      filterValue: string;
      joinOperator: null;
      joinConditions: null;
    }
  | {
      filterType: 'text';
      filterOption: null;
      filterValue: null;
      joinOperator: string;
      joinConditions: GridColumnJoinCondition[];
    }
  | {
      filterType: 'set';
      filterOption: null;
      filterValue: string[];
      joinOperator: null;
      joinConditions: null;
    };

export interface GridColumnJoinCondition {
  filterType: 'number' | 'text';
  filterOption: string;
  filterValue: number | string;
}

export type GridColumnFilterConfigForSchema<T extends GridColumnFilterConfig = GridColumnFilterConfig> = {
  [K in keyof GridColumnFilterConfig]: T[K];
};

export const ARC_FILTERED_VIEW_VERSION = '1.0.0';

export interface ArcCountConfiguration {
  linkedCategoryForNa: QUALIFYING_QUESTION_CATEGORY | null;
  label: string;
  field: string;
}

export const COUNT_CONFIGURATION: ArcCountConfiguration[] = [
  {
    linkedCategoryForNa: QUALIFYING_QUESTION_CATEGORY.LIMITING_OBJECTIONS,
    label: 'Rebuttals',
    field: 'rebuttals',
  },
  {
    linkedCategoryForNa: QUALIFYING_QUESTION_CATEGORY.INQUIRE,
    label: 'Probing',
    field: 'probing',
  },
];

export const NOT_APPLICABLE_LABEL = 'n/a';

export enum CALL_TYPE {
  CONTACT = 'Contact',
  INTERNAL_WT = 'Internal Warm Transfer',
  STANDARD = 'Standard',
}

export const ALL_ITEMS = 'All';
export const INTERNAL_WT_DISPOSITION = 'Contactor transferred';
export const INTERNAL_WT_BUT_NO_AVAILABILITY_DISPOSITION = 'Contactor WT ready, but no adv available';
export const CONTACTOR_EXCLUSIVE_DISPOSITIONS = [INTERNAL_WT_DISPOSITION, INTERNAL_WT_BUT_NO_AVAILABILITY_DISPOSITION];

export interface RecommendedResponse {
  id: string;
  linkedTagId: string;
  label: string;
  order: number;
  version: string;
  isDeleted: boolean;
  products: string[];
  carriers: string[];
  callTypes: string[];
  pods: string[];
  linkedTagDefinition?: CallTranscriptTagDefinition;
}

export const CALL_TRANSCRIPT_PAGE_PATH = 'call-transcript';
export const CALL_TRANSCRIPT_GRADING_SUMMARY_PAGE_PATH = 'transcript-grade-summary';
